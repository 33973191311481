import React from "react"
import Layout from "../components/layout"
import { graphql, Link } from "gatsby"
import { Container } from "reactstrap"
import SEO from "../components/seo"
import ImgPlaceholder from "../../static/assets/Ellipse 26.png"

const Blogs = ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <SEO
        title="Latest Posts"
        description={data.site.siteMetadata.description}
      />
      <div id="hero" className="blog-page">
        <div className="container">
          <div className="row">
            <div className="col-md-7 m-auto text-center">
              <div>
                <h1 className="hero-title">Latest Articles</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-section">
        <Container>
          <div className="row justify-content-between">
            <div className="col-lg-12">
              <div className="blog-item">
                <div className="row">
                  {data.allMarkdownRemark.edges.map(blog => (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 my-5"
                      key={blog.node.id}
                      style={{ background: "#fff" }}
                    >
                      <div
                        className="card border p-0"
                        style={{ border: "none" }}
                      >
                        <div
                          className="blog-img-box"
                          style={{
                            height: "18rem",
                            backgroundImage: `url(/${
                              blog.node.frontmatter.banner !== null
                                ? blog.node.frontmatter.banner
                                : "https://source.unsplash.com/user/joshhild/500x500"
                            })`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        />
                        <div className="card-body">
                          <h4 className="card-title mt-3 mb-3">
                            <Link
                              className="blue-btn text-blue"
                              to={blog.node.fields.slug}
                            >
                              {blog.node.frontmatter.title}
                            </Link>
                          </h4>
                          <p
                            className="card-text"
                            style={{
                              width: "100%",
                              height: "auto",
                            }}
                          >
                            {blog.node.frontmatter.excerpt.length >= 100
                              ? blog.node.frontmatter.excerpt.substring(
                                  0,
                                  100
                                ) + "..."
                              : blog.node.frontmatter.excerpt}
                          </p>
                          <Link
                            to={blog.node.fields.slug}
                            className="blue-btn text-blue font-700"
                          >
                            Read Now ➜
                          </Link>
                          <div className="footer-blog-wrap mt-3">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="mt-3">
                                  <img
                                    src={ImgPlaceholder}
                                    alt="BLog Author"
                                    className="img-fluid rounded-circle float-left mr-2"
                                    width={50}
                                  />
                                  <p className="m-0">
                                    <span className="text-muted small">
                                      <i className="fa fa-calendar-o pr-1" />
                                      {blog.node.frontmatter.publishedDate}
                                    </span>
                                  </p>
                                  <p>
                                    <span className="mr-3 small">
                                      by: {blog.node.frontmatter.author}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default Blogs

export const blogQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(posts)/" } }
      sort: { fields: frontmatter___publishedDate, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            banner
            publishedDate(formatString: "MMMM DD, YYYY")
            excerpt
            author
            metaTitle
            metaDescription
          }
        }
      }
    }
  }
`
